import { combineReducers, createStore } from "redux"

const mainReducer = (state = {}, action) => {
  switch (action.type) {
    case "MAIN":
      return {
        ...state,
        ...action.main,
      }

    default:
      return state
  }
}

const surveyReducer = (state = { cards: [], surveyId: null }, action) => {
  switch (action.type) {
    case "ADD_CARD":
      return {
        ...state,
        ...{ cards: state.cards.concat([action.data]) },
      }
    case "SURVEY_ID":
      return {
        ...state,
        ...{ surveyId: action.data },
      }
    case "FLAGS":
      return {
        ...state,
        ...{ flags: action.data },
      }
    case "UPDATE_OTHER_BANKING":
      return {
        ...state,
        ...action.data,
      }
    case "ESTIMATE_ACTION":
      return {
        ...state,
        ...{ estimateResult: action.data },
      }
    case "CLEAR_SURVEY":
      return { cards: [], surveyId: null }
    default:
      return state
  }
}

const store = createStore(
  combineReducers({
    main: mainReducer,
    survey: surveyReducer,
  })
)
store.subscribe(() => {
  //console.log(store.getState())
})
export default store
