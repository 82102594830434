import React from "react"
import { Provider } from "react-redux"
import store from "./src/state/store"
import { AuthProvider } from "state/AuthContext"

const wrapper = ({ element }: any) => {
  return <Provider store={store}>
    <AuthProvider>
      {element}
    </AuthProvider>
  </Provider>
}

export default wrapper
