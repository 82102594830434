import AppManager from "../services/app-manager"

export const mainAction = main => ({
  type: "MAIN",
  main,
})

export const step1Action = data => ({
  type: "STEP1",
  data,
})

export const step2Action = data => ({
  type: "STEP2",
  data,
})

export const step3Action = data => ({
  type: "STEP3",
  data,
})

export const updateOtherBanking = data => ({
  type: "UPDATE_OTHER_BANKING",
  data,
})

export const storeSurveyId = data => {
  if (typeof data === "undefined" || data === null) {
    AppManager.getInstance().handleError(null, "Couldn't find survey ID")
  } else {
  //  console.log("SurveyId =", data)
  }

  return {
    type: "SURVEY_ID",
    data,
  }
}

export const storeFlags = data => ({
  type: "FLAGS",
  data,
})

export const estimateAction = data => ({
  type: "ESTIMATE_ACTION",
  data,
})

export const addCard = data => ({
  type: "ADD_CARD",
  data,
})

export const clearSurvey = () => ({
  type: "CLEAR_SURVEY",
})
