import { navigate } from "gatsby"
import * as uuid from "uuid"
import store from "../state/store"
import { apis, acceptOffer, loadMain, config } from "@sog/sdk"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import allProvidersOverviewContentJson from "../../all-providers-overview-content.json"
import ccReducedContentJson from "../../cc-reduced-content.json"
import hlReducedContentJson from "../../hl-reduced-content.json"
import plReducedContentJson from "../../pl-reduced-content.json"
import clReducedContentJson from "../../cl-reduced-content.json"
import tdReducedContentJson from "../../td-reduced-content.json"
import txReducedContentJson from "../../tx-reduced-content.json"
import svReducedContentJson from "../../sv-reduced-content.json"
import providerSynonymsJson from "../../synonyms.json"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { mainAction } from "../state/actions"
import { apis as API } from "@sog/sdk"

config.init(process.env.REACT_APP_BASE_URL || "", process.env.GIT_INFO || "")

// import Content from "../models/Content"
// import PersonalLoanContent from "../models/content-models/PersonalLoanContent"
export type AppError = null | ((jsonError: string) => void)
const isDevelopment = () => {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development"
}
declare global {
  interface Console {
    olog: any
  }
  interface Window {
    UnFreezeUI: any
    FreezeUI: any
  }
}
class AppManager {
  private static instance: AppManager
  public static isBrowser = typeof window !== "undefined"

  private userDetails = null

  private pageIndex = 0
  private pageMap: any = {}
  private isDev = process.env.IS_DEV === "true"
  private isLogEnabled = this.isDev
  private clientDataVersion = "006"
  public isStable = false
  private shouldHandleErrors = true
  private authenticatedUser = ""
  private visitId = ""

  private allProviders: {[key: string]: any} = {}

  private ccProviders = {}
  private ccDateUpdated: string | undefined = undefined
  private ccRewards = {}
  private hlProviders = {}
  private hlDateUpdated: string | undefined = undefined

  private homeLoanAmount = 500000
  private loanDeposit = 40
  private loanPurpose = "Home"

  private personalLoanAmount = 30000
  private loanTerm = 5

  private depositInvestmentAmount = 50000
  private depositInvestmentTerm = 12

  private plProviders = {}
  private plDateUpdated: string | undefined = undefined
  private clProviders = {}
  private clDateUpdated: string | undefined = undefined

  private tdProviders = {}
  private tdDateUpdated: string | undefined = undefined

  private txProviders = {}
  private txDateUpdated: string | undefined = undefined

  private svProviders = {}
  private svDateUpdated: string | undefined = undefined
  private svDepositAmount = 50000

  // TODO: call this right after the main API in the app_manger class, after successful signin and signup
  public async getUserDetails() {
    // TODO: call the getUserDetails API
    API.getUserDetails({}, responseJson => {
      if (responseJson && responseJson.status === 200) {
        const newUserInitial = responseJson.firstName.charAt(0) + responseJson.lastName.charAt(0)
      }
    })
    // TODO: update this.userDetails with the json response or null if return with 40X
    // TODO: update UI
  }

  private constructor() {
    this.registerWindowErrorHandlers()
    this.allProviders = allProvidersOverviewContentJson.providers
    this.ccProviders = ccReducedContentJson.providers
    this.ccDateUpdated = "dateUpdated" in ccReducedContentJson ? ccReducedContentJson.dateUpdated as string : undefined
    this.ccRewards = ccReducedContentJson.rewards
    this.hlProviders = hlReducedContentJson.providers
    this.hlDateUpdated = "dateUpdated" in hlReducedContentJson ? hlReducedContentJson.dateUpdated as string : undefined
    this.plProviders = plReducedContentJson.providers
    this.plDateUpdated = "dateUpdated" in plReducedContentJson ? plReducedContentJson.dateUpdated as string : undefined
    this.clProviders = clReducedContentJson.providers
    this.clDateUpdated = "dateUpdated" in clReducedContentJson ? clReducedContentJson.dateUpdated as string : undefined
    this.tdProviders = tdReducedContentJson.providers
    this.tdDateUpdated = "dateUpdated" in tdReducedContentJson ? tdReducedContentJson.dateUpdated as string : undefined
    this.txProviders = txReducedContentJson.providers
    this.txDateUpdated = "dateUpdated" in txReducedContentJson ? txReducedContentJson.dateUpdated as string : undefined
    this.svProviders = svReducedContentJson.providers
    this.svDateUpdated = "dateUpdated" in svReducedContentJson ? svReducedContentJson.dateUpdated as string : undefined
  }

  public getOfferSurvey(offerId: string, provider: any, offerType: string, offer: any, dataToCollect: any) {
    apis.surveyOutcome({ surveyId: store.getState().survey.surveyId, ...dataToCollect })
    DataCollector.getInstance().addAction(DataCollectorAction.SURVEY_GET_OFFER_SELECTED, dataToCollect)
    const surveyId = store.getState().survey.surveyId
    this.getOffer(offerType, offerId, offer, provider.id, provider.name, surveyId)
  }

  public async getOffer(offerType: string, offerId: string, offer: any, providerId: number, providerName: string, surveyId = "") {
    const webpageUrl = window.location.pathname

    if (offerType === "Stay") {
      await acceptOffer(offerId, offerType, webpageUrl, surveyId)
      navigate("/credit-cards/product-transfer/", {
        state: {
          providerId: offer.providerId,
          offer,
        },
      })
    } else {
      if (typeof window !== `undefined`) window.Buffer = window.Buffer || require("buffer").Buffer
      const sid = typeof window !== `undefined` && surveyId ? window.Buffer.from(surveyId).toString("base64") : ""
      const webpage = typeof window !== `undefined` && webpageUrl ? window.Buffer.from(webpageUrl).toString("base64") : ""
      // const q = "pid=" + providerId + "&pname=" + providerName + "&o=" + o + `&vid=${this.getVisitId()}&t=${offerType}`
      const q = `pid=${providerId}&pname=${providerName}&o=${offerId}&vid=${this.getVisitId()}&t=${offerType}&sid=${sid}&webpage=${webpage}`
      const url = (isDevelopment() ? "/credit-cards/redirect?" : "/redirect/index.html?") + q

      if (typeof window !== `undefined`) {
        // https://stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
        const a = document.createElement("a")
        // a.style = "display: none"
        a.setAttribute("rel", "sponsored")
        a.setAttribute("href", url)
        a.setAttribute("target", "_blank")
        a.click()
      }
    }
  }

  public async getRefinanceOffer(providerIdOrName = "", forProductPage = false, surveyId = "", savings = 0, contactDetails: any = {}, offer: any[] = [], currentSituation: any[] = []) {
    const openRedirectPage = () => {
      window.Buffer = window.Buffer || require("buffer").Buffer
      const webpage = window.Buffer.from(forProductPage
        ? 'https://info.finspo.com.au/stayorgo-landing'
        : 'https://info.finspo.com.au/stayorgo'
      ).toString("base64")
      const url = (isDevelopment() ? "/home-loans/refinance?" : "/refinance-redirect/index.html?")
        + (`${forProductPage ? 'providerName' : 'providerId'}=${providerIdOrName}`)
        + (`&webpage=${webpage}`)

      const a = document.createElement("a")
      a.setAttribute("rel", "sponsored")
      a.setAttribute("href", url)
      a.setAttribute("target", "_blank")
      a.click()
      a.remove()
    }

    if (surveyId) {
      apis.useFinspoWebhook({surveyId, contactDetails, savings, offer, currentSituation}, (data: any) => {
        // redirect on successful call to webhook
        openRedirectPage()
      }, () => {
        // throw an error
        throw new Error("An error occurred when fetching the Finspo redirect URL")
      })
    } else {
      // redirect immediately
      openRedirectPage()
    }
  }

  public static getInstance(): AppManager {
    if (!AppManager.instance) {
      AppManager.instance = new AppManager()
    }

    return AppManager.instance
  }

  private initDataCollector() {
    DataCollector.getInstance().init(
      this.visitId,
      "",
      (jsonData: any) => trackCustomEvent(jsonData),
      (error: any) => AppManager.getInstance().handleError(error)
    )
  }

  public async onClientEntry() {
    try {
      await this.clearCacheAndStorageIfNeeded()
      window.localStorage.setItem("sogv", this.clientDataVersion)
    } catch (error) {
    //  console.log(error)
    }

    try {
      apis.init(
        (jsonData: any) => store.dispatch(mainAction(jsonData)),
        (error: any, isStable: boolean) => {
          if (!isStable) AppManager.getInstance().isStable = false
          AppManager.getInstance().handleError(null, { msg: JSON.stringify(error) })
        },
        () => store.getState().survey.flags
      )
      // opening an offer in a new tab shouldn't create a new visitId (keep the visitId of the page who triggered that event)
      var searchParams = new URLSearchParams(window.location.search)
      const queryVisitId = searchParams.get("vid")
      if (queryVisitId) {
        this.visitId = queryVisitId
        apis.visitId = this.visitId
        this.initDataCollector()
      } else {
        this.createVisitId()
      }

      this.isStable = true
    } catch (e) {
      this.handleError(e, null)
    }

    // https://github.com/alexradulescu/FreezeUI

    if (!AppManager.isBrowser) return

    /**
     * Setup the freeze element to be appended
     */
    let freezeHtml = document.createElement("div")
    freezeHtml.classList.add("freeze-ui")

    /**
     * Freezes the UI
     * options = {
     *   selector: '.class-name' -> Choose an element where to limit the freeze or leave empty to freeze the whole body. Make sure the element has position relative or absolute,
     *   text: 'Magic is happening' -> Choose any text to show or use the default "Loading". Be careful for long text as it will break the design.
     * }
     */
    window.FreezeUI = (options: any = {}) => {
      let parent = document.querySelector(options.selector) || document.body
      freezeHtml.setAttribute("data-text", options.text || "Loading")
      if (document.querySelector(options.selector)) {
        freezeHtml.style.position = "absolute"
      }
      parent.appendChild(freezeHtml)
    }

    /**
     * Unfreezes the UI.
     * No options here.
     */
    window.UnFreezeUI = () => {
      let element = document.querySelector(".freeze-ui")
      if (element) {
        element.classList.add("is-unfreezing")
        setTimeout(() => {
          if (element) {
            element.classList.remove("is-unfreezing")
            element.parentElement!.removeChild(element)
          }
        }, 0)
      }
    }

    window.FreezeUI()
  }

  private async createVisitId() {
    this.visitId = await loadMain()
    await this.getUserDetails()
    apis.visitId = this.visitId
    this.initDataCollector()
    if (!AppManager.isBrowser) return
    window.UnFreezeUI() // Will unfreeze any and all options from above
  }

  public getVisitId() {
    return this.visitId
  }

  public getAllProvidersOverviewContent() {
    return this.allProviders
  }

  public getProviderOverviewContentById(pid: string) {
    if (pid in this.allProviders) {
      return this.allProviders[pid]
    }
    return {}
  }

  public getReducedCreditCardProviders() {
    return this.ccProviders
  }

  public getReducedCreditCardProductById(pid: string) {
    let product = {}
    Object.values(this.ccProviders).forEach(provider => {
      Object.keys(provider).forEach(productId => {
        if (productId == pid) {
          product = provider[productId]
        }
      })
    })
    return product
  }

  public getReducedCreditCardRewards() {
    return this.ccRewards
  }

  public getReducedHomeLoanProviders() {
    return this.hlProviders
  }

  public getReducedHomeLoanProviderById(id: string) {
    return this.hlProviders[id]
  }

  public getReducedPersonalLoanProviders() {
    return this.plProviders
  }

  public getReducedCarLoanProviders() {
    return this.clProviders
  }

  public getReducedTermDepositProviders() {
    return this.tdProviders
  }

  public getReducedTransactionAccountProviders() {
    return this.txProviders
  }

  public getReducedSavingsAccountProviders() {
    return this.svProviders
  }

  public getProductCategoryDateUpdated(category?: "cc" | "hl" | "pl" | "cl" | "td" | "tx" | "sv") {
    if (category === "cc") return this.ccDateUpdated
    if (category === "hl") return this.hlDateUpdated
    if (category === "pl") return this.plDateUpdated
    if (category === "cl") return this.clDateUpdated
    if (category === "td") return this.tdDateUpdated
    if (category === "tx") return this.txDateUpdated
    if (category === "sv") return this.svDateUpdated
  }

  public getHomeLoanAmount() {
    return this.homeLoanAmount
  }

  public getLoanDeposit() {
    return this.loanDeposit
  }

  public getLoanPurpose() {
    return this.loanPurpose
  }

  public getDepositInvestmentAmount() {
    return this.depositInvestmentAmount
  }

  public getDepositInvestmentTerm() {
    return this.depositInvestmentTerm
  }

  public getPersonalLoanAmount() {
    return this.personalLoanAmount
  }

  public getLoanTerm() {
    return this.loanTerm
  }

  public getSavingsDepositAmount() {
    return this.svDepositAmount
  }

  public setHomeLoanAmount(v: number) {
    this.homeLoanAmount = v
  }

  public setLoanDeposit(v: number) {
    this.loanDeposit = v
  }

  public setLoanPurpose(v: string) {
    this.loanPurpose = v
  }

  public setDepositInvestmentAmount(v: number) {
    this.depositInvestmentAmount = v
  }

  public setDepositInvestmentTerm(v: number) {
    this.depositInvestmentTerm = v
  }

  public setPersonalLoanAmount(v: number) {
    this.personalLoanAmount = v
  }

  public setLoanTerm(v: number) {
    this.loanTerm = v
  }

  public setSavingsDepositAmount(v: number) {
    this.svDepositAmount = v
  }

  public getProviderSynonyms() {
    return providerSynonymsJson
  }

  public onPreRouteUpdate(location: any, prevLocation: any) {
    const redirects = require("/redirects.json")
    redirects.forEach((redirect: { fromPath: string; toPath: string }) => {
      if (redirect.fromPath === location.pathname) {
        navigate(redirect.toPath)
      } else if (redirect.fromPath.charAt(redirect.fromPath.length - 1) === "*" && location.pathname.startsWith(redirect.fromPath.substring(0, redirect.fromPath.length - 1))) {
        if (redirect.toPath.charAt(redirect.toPath.length - 1) === "*") {
          navigate(redirect.toPath.substring(0, redirect.toPath.length - 1) + location.pathname.substring(redirect.fromPath.length - 1))
        } else {
          navigate(redirect.toPath)
        }
      }
    })
  }

  public onRouteUpdate(location: any, prevLocation: any) {
    if (location.key in this.pageMap) {
      let action
      if (this.pageMap[location.key] < this.pageMap[prevLocation.key]) {
        // Browser back button
        action = DataCollectorAction.BROWSER_BACK
      } else {
        // Browser forward button
        action = DataCollectorAction.BROWSER_FORWARD
      }
      DataCollector.getInstance().addAction(action, { ...(store.getState().isSurveyBack && { surveyBack: true }) }, prevLocation.pathname + prevLocation.search)
      store.getState().isSurveyBack = false
    } else {
      this.pageMap[location.key] = this.pageIndex
      this.pageIndex += 1
    }
    DataCollector.getInstance().addAction(DataCollectorAction.URL_CHANGE)
    const protectedPages = ["/offer-qa", "/offer-qa/offers", "/offer-qa/offer-details"]
    if (protectedPages.includes(location.pathname.replace(/\/+$/, "")) && !AppManager.getInstance().getAuthenticatedUser()) {
      navigate("/offer-qa/login")
    }
    const notScrollToTopRoutes = ["/credit-cards/offers", "/offer-qa/offers", "/credit-cards/"]
    if (!notScrollToTopRoutes.includes(location.pathname)) {
      try {
        // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
        window.scroll({ top: 0, left: 0, behavior: "auto" })
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0)
      }
    }
  }

  public handleError(e: any, data: any = null) {
    // console.log("will handleError", this.isStable, this.shouldHandleErrors, e, data)
    // Prevent an infinite loops of errors
    if (!this.shouldHandleErrors) return
    if (!this.isStable) this.shouldHandleErrors = false
    // console.log("handleError", e, data)
    try {
      const error = data
        ? data
        : {
            message: e.message,
            name: e.name,
            ...(e.stack && { stack: e.stack }),
          }

      const errorId = `${Date.now()}-${uuid.v4()}`
      error["id"] = errorId
      apis.errorReport(error, () => {
        if (!this.isStable) document.getElementsByTagName("body")[0].innerHTML = "Something went wrong. Please check your internet connection."
      })
      console.error(error)
      if (!this.isStable) document.getElementsByTagName("body")[0].innerHTML = "Something went wrong, ref number: " + errorId
    } catch (error) {
      if (!this.isStable) document.getElementsByTagName("body")[0].innerHTML = "Something went wrong."
      console.error(error)
    }
  }

  private async clearCacheAndStorageIfNeeded() {
    let storedVersion = window.localStorage.getItem("sogv")
  //  console.log("DEBUG: storedVersion ", storedVersion)
    if (!storedVersion || this.clientDataVersion !== storedVersion) {
    //  console.log("DEBUG: will delete local and session storage")
      window.localStorage.clear()
    //  console.log("DEBUG: did delete local and session storage")

      if (typeof caches !== "undefined" && caches !== null) {
        const names = await caches.keys()
        if (names.length > 0) {
          // https://dev.to/flexdinesh/cache-busting-a-react-app-22lk/comments
          await Promise.all(names.map(name => caches.delete(name)))
        //  console.log("DEBUG: cleared cache -- will reload the page ")
          window.location.reload()
        //  console.log("DEBUG: cleared cache -- did reload the page ")
        }
      } else {
      //  console.log("no cache")
      }
    }
  }

  private registerWindowErrorHandlers() {
    if (!AppManager.isBrowser) return

    if (typeof console != "undefined" && typeof console.log != "undefined") {
      console.olog = console.log
      console.log = function (message?: any, ...optionalParams: any[]) {
        if (AppManager.getInstance().isLogEnabled) console.olog(message, ...optionalParams)
      }
      console.debug = console.info = console.log
    }

    // https://stackoverflow.com/questions/951791/javascript-global-event-mechanism
    window.onerror = (msg, url, line, col, error) => {
      const errorData = { msg, url, line, col, ...(error && error.stack && { errorStack: error.stack }), ...(error && { errorMsg: error.message }), ...(error && { errorName: error.name }) }
      this.handleError(null, errorData)
      // When the function returns true, this prevents the firing of the default event handler
      return true
    }

    window.onunhandledrejection = (e: PromiseRejectionEvent) => {
      this.handleError(null, e.reason.stack || e.reason)
      // Prevent the default handling (such as outputting the error to the console)
      e.preventDefault()
    }
  }

  public setAuthenticatedUser(email: string) {
    this.authenticatedUser = email
  }

  public getAuthenticatedUser() {
    if (this.authenticatedUser) {
      return this.authenticatedUser
    }
    return null
  }
}

export default AppManager
